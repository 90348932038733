import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from '../blog/Footer';
import Divider from '@mui/material/Divider';
import { Button, Grid, Paper, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import Header from '../blog/Header'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { postData, getData } from '../../utils/Fetch'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";

export default function SuccessReport() {
    const { id } = useParams();

    const [tipoReporte, setTipoReporte] = React.useState('');
    const [persons, setPersons] = React.useState([{
        nombre: '',
        apellido: '',
        cargo: '',
        empresa: ''

    }])
    const [nombre, setNombre] = React.useState('');
    const [apellido, setApellido] = React.useState('');


    return (
        <>
            <Header />
            <Grid container
                style={{ 'paddingBottom': '1%', 'height': '100%' }}
                justifyContent="center"
                alignItems={"center"}
                direction={'column'}
            >
                <Grid item style={{ 'margin': '7%' }} md={10} >
                    <Typography variant='h4' align='center'>
                        <b>El caso fue ingresado con éxito,</b>
                    </Typography>
                    <Typography variant='h4' align='center'>
                        <b>
                            tu codigo de seguimiento es :</b>
                    </Typography>
                </Grid>

                <Grid item md={8} style={{ 'padding': '6% 13%', 'backgroundColor': '#db091a', 'borderRadius': '10px' }}>
                    <Typography variant="h4" component="div" align='center' minWidth={'100%'} color={'white'}>
                        <b>{id}</b>
                    </Typography>
                </Grid>

                <Grid item style={{ 'margin': '5%' }} md={10} >
                    <Typography variant='h6' align='center'>
                        Puedes hacer seguimiento del caso ingresando a
                    </Typography>
                    <Typography variant='h6' align='center'>
                        {'la sección '}
                        <Link href='/searchreport' color={'inherit'} underline='none'>
                            <i> Consultar Reporte </i>
                        </Link>
                    </Typography>
                </Grid>

            </Grid>
        </>
    );
}
