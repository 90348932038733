import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from '../blog/Footer';
import Divider from '@mui/material/Divider';
import { Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import Header from '../blog/Header'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { postData, getData } from '../../utils/Fetch'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';


// TODO remove, this demo shouldn't need to reset the theme.

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


export default function CreateReport() {

    let navigate = useNavigate();
    const [token, setToken] = React.useState(null);

    const [tipoReporte, setTipoReporte] = React.useState('');
    const [sede, setSede] = React.useState('');
    const [persons, setPersons] = React.useState([{
        nombre: '',
        apellido: '',
        cargo: '',
        empresa: ''

    }])
    const [lugar, setLugar] = React.useState([{ name: '' }]);
    const [fechaDesde, setFechaDesde] = React.useState(dayjs(new Date()));
    const [fechaHasta, setFechaHasta] = React.useState(dayjs(new Date()));
    const [description, setDescription] = React.useState('');
    const [evidencia, setEvidencia] = React.useState([]);
    const [evidenciaTexto, setEvidenciaTexto] = React.useState('');
    const [conocimientoSucesos, setConocimientoSuceso] = React.useState('');
    const [personasExternas, setPersonasExternas] = React.useState('');
    const [ocultanHechos, setOcultanHechos] = React.useState('');
    const [personalConoce, setPersonalConoce] = React.useState('');
    const [personalInvolucrado, setPersonalInvolucrado] = React.useState('');
    const [beneficiaPersonas, setBeneficiaPersona] = React.useState('');
    const [testigos, setTestigos] = React.useState('');
    const [relacionEmpresa, setRelacionEmpresa] = React.useState('');
    const [anonimato, setAnonimato] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [celular, setCelular] = React.useState('');
    const [filesComponent, setFilesComponent] = React.useState('');
    const [tipoComponent, setTipoComponent] = React.useState(null);
    const [sedeComponent, setSedeComponent] = React.useState(null);

    const handleFileUpload = (event) => {
        const files = event.target.files;

        let component = []

        for (let file of files) {
            component.push(
                <ListItem key={file.name}>
                    <ListItemAvatar>
                        <Avatar
                            alt={`Avatar n°`}
                            src={URL.createObjectURL(file)}
                        />
                    </ListItemAvatar>
                    <ListItemText primary={file.name} />
                </ListItem>
            )
        }
        setEvidencia(files)
        setFilesComponent(component)
    };

    const handleUpdatePerson = (event, state) => {
        const person_id = parseInt(event.target.id)
        const value = event.target.value
        let data = persons
        data[person_id][state] = value
        setPersons([...data])
    }

    const handleChangeLugar = (index, value) => {
        const newValues = [...lugar];
        newValues[index]['name'] = value;
        setLugar(newValues);
    };

    useEffect(() => {
        getData('https://lineaeticapedregal.com/api/v1/tipos').then((response) => {
            console.log(response)
            let component = []
            for (let file of response) {
                component.push(<MenuItem value={file.id} key={file.id}>{file.name}</MenuItem>)
            }
            setTipoComponent(component)
        })
        getData('https://lineaeticapedregal.com/api/v1/sede').then((response) => {
            console.log(response)
            let component = []
            for (let r of response) {
                component.push(<MenuItem value={r.sedeId} key={r.sedeId}>{r.sede}</MenuItem>)
            }
            setSedeComponent(component)
        })
    }, []);


    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const token = await recaptchaRef.current.executeAsync();

        // Campos necesarios
        const requiere = [tipoReporte, persons[0].nombre, persons[0].apellido, persons[0].cargo, persons[0].empresa, lugar[0], description]
        let empy_field = requiere.some((e) => !e)

        if (empy_field) return window.alert("Completar los campos obligatorios")

        const formData = new FormData();
        for (let p of persons) {
            formData.append('persons', JSON.stringify(
                {
                    name: p.nombre,
                    lastName: p.apellido,
                    cargo: p.cargo,
                    company: p.empresa
                }
            ))
        }
        for (let l of lugar) {
            formData.append('places', JSON.stringify(l))
        }
        formData.append('fechaInicio', fechaDesde)
        formData.append('fechaFin', fechaHasta)
        formData.append('tiposId', tipoReporte)
        formData.append('sedeId', sede)
        formData.append('description', description)
        for (let file of evidencia) {
            formData.append('evidence', file)
        }
        formData.append('referenceEv', evidenciaTexto)
        formData.append('res1', conocimientoSucesos)
        formData.append('res2', personasExternas)
        formData.append('res3', ocultanHechos)
        formData.append('res4', personalConoce)
        formData.append('res5', personalInvolucrado)
        formData.append('res6', beneficiaPersonas)
        formData.append('res7', testigos)
        formData.append('contact', JSON.stringify({ relation: relacionEmpresa, anonymous: anonimato, email: email, phone: celular }))

        var requestOptions = {
            method: 'POST',
            body: formData,
            redirect: 'follow',
            headers: {
                'Authorization': token 
            }
        };

        const response = await fetch("https://lineaeticapedregal.com/api/v1/reports", requestOptions)
        const rp = await response.json()
        console.log("respuesta", rp)

        if (rp.id) {
            navigate("/successreport/" + rp.id)
        }
        else {
            window.alert("Error en la creación")
        }

    };

    const recaptchaRef = React.createRef();

    return (
        <>
            <Header />
            <form onSubmit={handleOnSubmit}>
                <Grid container style={{ 'backgroundColor': '#f1f1f1', 'paddingBottom': '1%' }} justifyContent="center">

                    <Grid item style={{ 'padding': '1%' }}>
                        <Typography>
                            <b>Los campos que tengan un asterisco (*) deben completarse de manera obligatoria</b>
                        </Typography>
                    </Grid>

                    <Grid item md={11} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item md={12} xs={12}>
                                <Paper style={{ 'padding': '2%' }}>
                                    <Typography style={{ 'marginTop': '1%' }}>
                                        <b>INFORMACIÓN SOBRE EL REPORTE*</b>
                                    </Typography>
                                    <Typography style={{ 'marginTop': '1%' }}>
                                        Escoge el tipo de reporte que deseas registrar
                                    </Typography>
                                    <Grid item md={3} xs={12} sm={6}>
                                        <Select style={{ 'marginTop': '1%' }}
                                            id="demo-simple-select"
                                            placeholder='Seleccionar'
                                            value={tipoReporte}
                                            // label='Tipo de Reporte'
                                            fullWidth
                                            size='small'
                                            onChange={(event) => { setTipoReporte(event.target.value) }}
                                        >
                                            {tipoComponent}
                                        </Select>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={11} xs={12} style={{ 'marginTop': '1%' }} >
                        <Paper style={{ 'padding': '2%' }}>
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography>
                                        <b>IDENTIFICACIÓN DE PERSONAS INVOLUCRADAS*</b>
                                    </Typography>
                                </Grid>
                            </Grid>

                            {persons.map((value, index) => (
                                <Grid container direction="row" spacing={2} key={'nombre-' + index.toString()} style={{ marginTop: '1%' }}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Persona {index + 1}
                                            {index > 0 ?
                                                <IconButton onClick={() => {
                                                    let data = [...persons]
                                                    data.pop(index)
                                                    setPersons(data)
                                                }
                                                }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                : <></>
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3} xs={6}>
                                        <TextField
                                            id={index.toString()}
                                            placeholder='Nombre'
                                            fullWidth
                                            size="small"
                                            value={value.nombre}
                                            onChange={(event) => { handleUpdatePerson(event, 'nombre') }}
                                        />
                                    </Grid>
                                    <Grid item md={3} xs={6}>
                                        <TextField
                                            id={index.toString()}
                                            placeholder='Apellido'
                                            fullWidth
                                            size="small"
                                            value={value.apellido}
                                            onChange={(event) => { handleUpdatePerson(event, 'apellido') }}
                                        />
                                    </Grid>
                                    <Grid item md={3} xs={6}>
                                        <TextField
                                            id={index.toString()}
                                            placeholder='Cargo'
                                            fullWidth
                                            size="small"
                                            value={value.cargo}
                                            onChange={(event) => { handleUpdatePerson(event, 'cargo') }}
                                        />
                                    </Grid>
                                    <Grid item md={3} xs={6}>
                                        <TextField
                                            id={index.toString()}
                                            placeholder='Empresa'
                                            fullWidth
                                            size="small"
                                            value={value.empresa}
                                            onChange={(event) => { handleUpdatePerson(event, 'empresa') }}
                                        />
                                    </Grid>
                                </Grid>
                            ))}


                            <Grid item style={{ 'marginTop': '2%' }}>
                                <Button
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                    style={{ 'color': '#db091a', 'borderColor': '#db091a' }}
                                    onClick={() => {
                                        setPersons([...persons, {
                                            nombre: '',
                                            apellido: '',
                                            cargo: '',
                                            empresa: ''

                                        }])
                                    }}
                                >
                                    Añadir persona
                                </Button>
                            </Grid>
                        </Paper>
                    </Grid>



                    <Grid item md={11} xs={12} style={{ 'marginTop': '1%' }} >
                        <Paper style={{ 'padding': '2%' }}>
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography>
                                        <b>IDENTIFICACIÓN DEL LUGAR DEL SUCESO*</b>
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item md={2} xs={12} sm={4}>
                                <Grid container direction="column" style={{ marginTop: '1%' }}>
                                    <Typography style={{ 'marginTop': '1%' }}>
                                        Sede
                                    </Typography>
                                    <Select
                                        id="demo-simple-select"
                                        placeholder='Seleccionar'
                                        value={sede}
                                        label='Sede'
                                        fullWidth
                                        size='small'
                                        onChange={(event) => { setSede(event.target.value) }}
                                    >
                                        {sedeComponent}
                                    </Select>
                                </Grid>
                            </Grid>

                            {lugar.map((value, index) => (
                                <Grid container direction="column" spacing={1} key={'nombre-' + index.toString()} style={{ marginTop: '1%' }}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Lugar {index + 1}
                                            {index > 0 ?
                                                <IconButton onClick={() => {
                                                    let data = [...lugar]
                                                    data.pop(index)
                                                    setLugar(data)
                                                }
                                                }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                : <></>
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-controlled"
                                            fullWidth
                                            size="small"
                                            value={value.name}
                                            onChange={(event) => { handleChangeLugar(index, event.target.value) }}
                                        />
                                    </Grid>
                                </Grid>
                            ))}



                            <Grid item style={{ 'marginTop': '2%' }}>
                                <Button
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                    style={{ 'color': '#db091a', 'borderColor': '#db091a' }}
                                    onClick={() => {
                                        setLugar([...lugar, { name: '' }])
                                    }}
                                >
                                    Añadir lugar
                                </Button>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item md={11} xs={12} style={{ 'marginTop': '1%' }} >
                        <Paper style={{ 'padding': '2%' }}>
                            <Grid item>
                                <Typography>
                                    <b>INDIQUE LA FECHA EN LA QUE OCURRIÓ EL SUCCESO</b>
                                </Typography>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item style={{ 'marginTop': '1%' }} md={4}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                            <DatePicker
                                                label="De:"
                                                value={fechaDesde}
                                                onChange={(newValue) => setFechaDesde(newValue)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item style={{ 'marginTop': '1%' }} md={4}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                            <DatePicker
                                                label="Hasta:"
                                                value={fechaHasta}
                                                onChange={(newValue) => setFechaHasta(newValue)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item md={11} xs={12} style={{ 'marginTop': '1%' }} >
                        <Paper style={{ 'padding': '2%' }}>

                            <Typography>
                                <b>DESCRIPCIÓN DEL SUCESO*</b>
                            </Typography>
                            <Typography>
                                Describa los hechos que detallen su reporte y ayuden a su seguimiento
                            </Typography>

                            <Grid item md={8} xs={12}>
                                <TextField
                                    id="outlined-multiline-controlled"
                                    multiline
                                    fullWidth
                                    rows={4}
                                    placeholder='Escriba su texto aqui'
                                    value={description}
                                    onChange={(event) => { setDescription(event.target.value) }}
                                />

                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item md={11} xs={12} style={{ 'marginTop': '1%' }} >
                        <Paper style={{ 'padding': '2%' }}>


                            <Typography>
                                <b>EVIDENCIAS</b>
                            </Typography>
                            <Typography>
                                El envío de evidencias,
                                constituirá información de gran valor para ayudar a la investigación del caso.
                                Pueden ser documentos, gráficos, fotos, croquis, comprobantes u otra evidencia relevante.
                                ¿Posee usted evidencia física o digital que pueda ayudar en la investigación de esta preocupación?
                            </Typography>


                            <Grid item md={6} xs={12}>
                                <Button
                                    component="label"
                                    variant="contained"
                                    startIcon={<CloudUploadIcon />}
                                    style={{ 'backgroundColor': '#db091a', 'marginTop': '2%' }}
                                    onChange={handleFileUpload}
                                >
                                    Upload file
                                    <VisuallyHiddenInput type="file" multiple />
                                </Button>
                                <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                    {filesComponent}
                                </List>

                            </Grid>

                            <Typography style={{ 'marginTop': '3%' }}>
                                ¿Cómo, dónde y/o con quién se podría obtener evidencias del reporte realizado?
                                (documentación, lugares, personas)
                            </Typography>


                            <Grid item md={8} xs={12}>
                                <TextField
                                    id="outlined-multiline-controlled"
                                    placeholder="Escriba su texto aqui"
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={evidenciaTexto}
                                    onChange={(event) => { setEvidenciaTexto(event.target.value) }}
                                />
                            </Grid>
                        </Paper>
                    </Grid>


                    <Grid item md={11} xs={12} style={{ 'marginTop': '1%' }} >
                        <Paper style={{ 'padding': '2%' }}>


                            <Typography variant='h6'>
                                <b>INFORMACIÓN ADICIONAL DEL REPORTE (OPCIONAL)</b>
                            </Typography>
                            <Typography variant='h7'>
                                <b>El contenido de esta sección nos ayudará a analizar el reporte</b>
                            </Typography>
                            <Typography>
                                ¿Cómo tuvo conocimiento de los sucesos reportados?
                            </Typography>
                            <Grid item md={8} xs={12}>
                                <TextField
                                    id="outlined-multiline-controlled"
                                    placeholder='Escriba su texto aqui'
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={conocimientoSucesos}
                                    onChange={(event) => { setConocimientoSuceso(event.target.value) }}
                                />
                            </Grid>

                            <Typography style={{ 'marginTop': '3%' }}>
                                ¿Este suceso está relacionado a personas externas a la compañía?
                            </Typography>
                            <Grid item md={8} xs={12}>
                                <TextField
                                    id="outlined-multiline-controlled"
                                    placeholder='Escriba su texto aqui'
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={personasExternas}
                                    onChange={(event) => { setPersonasExternas(event.target.value) }}
                                />
                            </Grid>

                            <Typography style={{ 'marginTop': '3%' }}>
                                A su parecer ¿Cree que se ocultan los hechos de alguna forma?
                            </Typography>
                            <Grid item md={8} xs={12}>
                                <TextField
                                    id="outlined-multiline-controlled"
                                    placeholder='Escriba su texto aqui'
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={ocultanHechos}
                                    onChange={(event) => { setOcultanHechos(event.target.value) }}
                                />
                            </Grid>

                            <Typography style={{ 'marginTop': '3%' }}>
                                Aparte de Ud. ¿Algún personal de la empresa conoce el suceso reportado?
                            </Typography>
                            <Grid item md={8} xs={12}>
                                <TextField
                                    id="outlined-multiline-controlled"
                                    placeholder='Escriba su texto aqui'
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={personalConoce}
                                    onChange={(event) => { setPersonalConoce(event.target.value) }}
                                />
                            </Grid>

                            <Typography style={{ 'marginTop': '3%' }}>
                                ¿Qué relación existe entre el personal involucrado en el suceso reportado?
                            </Typography>
                            <Grid item md={8} xs={12}>
                                <TextField
                                    id="outlined-multiline-controlled"
                                    placeholder='Escriba su texto aqui'
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={personalInvolucrado}
                                    onChange={(event) => { setPersonalInvolucrado(event.target.value) }}
                                />
                            </Grid>

                            <Typography style={{ 'marginTop': '3%' }}>
                                ¿Cómo se benefician las personas en el hecho reportado?
                            </Typography>
                            <Grid item md={8} xs={12}>
                                <TextField
                                    id="outlined-multiline-controlled"
                                    placeholder='Escriba su texto aqui'
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={beneficiaPersonas}
                                    onChange={(event) => { setBeneficiaPersona(event.target.value) }}
                                />
                            </Grid>

                            <Typography style={{ 'marginTop': '3%' }}>
                                ¿Conoce a otros testigos que pueden apoyar su versión y contribuir con evidencia del caso?
                            </Typography>
                            <Grid item md={8} xs={12}>
                                <TextField
                                    id="outlined-multiline-controlled"
                                    placeholder='Escriba su texto aqui'
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={testigos}
                                    onChange={(event) => { setTestigos(event.target.value) }}
                                />
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item md={11} xs={12} style={{ 'marginTop': '1%' }} >
                        <Paper style={{ 'padding': '2%' }}>

                            <Typography>
                                <b>OPCIONES DE CONTACTO</b>
                            </Typography>

                            <Typography>
                                ¿Cuál es su relación con la empresa?
                            </Typography>

                            <Grid item md={8} xs={12}>
                                <TextField
                                    id="outlined-multiline-controlled"
                                    placeholder='Escriba su texto aqui'
                                    multiline
                                    fullWidth
                                    rows={4}
                                    value={relacionEmpresa}
                                    onChange={(event) => { setRelacionEmpresa(event.target.value) }}
                                />
                            </Grid>

                            <Typography style={{ 'marginTop': '3%' }}>
                                <b>¿Desea mantener su anonimato?</b>
                            </Typography>
                            <FormControlLabel
                                control={<Checkbox
                                    value={anonimato}
                                    onChange={(event) => { setAnonimato(event.target.checked) }}
                                // onChange={(event) => { console.log(event) }}
                                />}
                                label="Sí, deseo mantener mi anonimato"
                            />

                            <Typography style={{ 'marginTop': '3%' }}>
                                ¿ Desea proporcionar un número telefónico o cuenta de correo electrónico para mantener contacto?
                            </Typography>

                            <Grid container direction="row" spacing={3}>
                                <Grid item md={3} xs={6}>
                                    <TextField
                                        id="outlined-basic"
                                        placeholder='Email'
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={email}
                                        onChange={(event) => { setEmail(event.target.value) }}
                                    />
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <TextField
                                        id="outlined-basic"
                                        placeholder='Celular/Telefono'
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={celular}
                                        onChange={(event) => { setCelular(event.target.value) }}
                                    />
                                </Grid>
                            </Grid>

                        </Paper>
                    </Grid>

                    <Grid item style={{ 'margin': '4%' }}>
                        <Typography>
                            <b>Después de presionar “Enviar” aparecerá un mensaje de confirmación
                                con el código generado por el reporte interpuesto a fin de que pueda realizar consultas
                                y hacer seguimiento al estado del reporte.</b>
                        </Typography>


                    </Grid>

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LduoU0pAAAAAI2-vm5gQ4hJS066X-KTL17SQw6D"
                        size="invisible"
                    />

                    <Grid item md={3}>
                        <Button
                            variant="contained"
                            style={{ 'backgroundColor': '#db091a', 'marginTop': '1%' }}
                            fullWidth
                            type='submit'
                        >
                            Enviar
                        </Button>
                    </Grid>

                </Grid>
            </form>
            {/* <Divider variant="middle" style={{ 'marginTop': '0.5%' }} /> */}
            <Footer
                // title="Footer"
                description="Puedes contactarnos a esta dirección de correo electrónico: lineaetica@elpedregalsa.com"
            />
        </>
    );
}
