import * as React from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import Header from '../blog/Header'

import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getData } from '../../utils/Fetch'
import moment from 'moment';
import Container from '@mui/material/Container';



export default function CreateReport() {
    const [id, setId] = React.useState('');

    const [numero, setNumero] = React.useState('');
    const [fecha, setFecha] = React.useState('');
    const [estatus, setEstatus] = React.useState('');
    const [observacion, setObservacion] = React.useState('');

    const ClearState = () => {
        setNumero("")
        setFecha("")
        setEstatus("")
        setObservacion("")
    }


    const searchReport = () => {
        getData('https://lineaeticapedregal.com/api/v1/reports/' + id).then((response) => {
            console.log(response)
            if (response && response.error) {
                ClearState()
                window.alert('Error con API')
            }
            else if (response) {
                setNumero(response.id)
                const date = moment(response.createAt).format('DD-MM-yyyy')
                setFecha(date)
                setEstatus(response.condition.name)
                setObservacion(response.observation)
            } else {
                ClearState()
            }
        })
    }


    return (
        <>
            <Header />

            <Grid container spacing={3} justifyContent="center" alignItems="center" direction="column">
                <Grid item xs={6} style={{ 'marginTop': '2%' }}>
                    <Grid container justifyContent="flex-start">
                        <Grid item xs={12} >
                            <Typography variant='h4' style={{ 'width': '100%' }}>
                                <b>Consulta:</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6} style={{ 'marginBottom': '5%' }}>
                    <Paper
                        component="form"
                        sx={{ display: 'flex', alignItems: 'center', width: 400 }}
                        onSubmit={(event) => {
                            event.preventDefault();
                            searchReport();
                        }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search"
                            onChange={(event) => { setId(event.target.value) }}
                            value={id}
                        />
                        <IconButton type="button" aria-label="search" onClick={searchReport}>
                            <SearchIcon />
                        </IconButton>

                    </Paper>
                </Grid>

                <Container>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead style={{ backgroundColor: '#db091a' }}>
                                <TableRow>
                                    <TableCell align='center' style={{ width: '30%', color: 'white' }}><b>Información</b></TableCell>
                                    <TableCell align='center' style={{ width: '70%', color: 'white' }}><b>Detalle</b></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" align="center">
                                        <b>N° Reporte</b>
                                    </TableCell>
                                    <TableCell align="center">{numero}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell component="th" scope="row" align="center">
                                        <b>Fecha de Registro</b>
                                    </TableCell>
                                    <TableCell align="center">{fecha}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell component="th" scope="row" align="center">
                                        <b>Estatus del Reporte</b>
                                    </TableCell>
                                    <TableCell align="center">{estatus}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell component="th" scope="row" align="center">
                                        <b>Observaciones</b>
                                    </TableCell>
                                    <TableCell align="center">{observacion}</TableCell>
                                </TableRow>
                            </TableBody>
                            
                        </Table>
                    </TableContainer>
                </Container>

            </Grid>
        </>
    );
}
