import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import Footer from './Footer';
import Divider from '@mui/material/Divider';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Blog() {
  return (
    <>
      <Header title="Blog" />
      
      <MainFeaturedPost />
      
      <Divider variant="middle" style={{ 'marginTop': '5%' }} />
      <Footer
        description="Puedes contactarnos a esta dirección de correo electrónico: lineaetica@elpedregalsa.com"
      />
    </>
  );
}
