import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '150px',
  maxHeight: '100%',
});

function Header(props) {
  const [token, setToken] = React.useState('');
  let navigate = useNavigate();

  const logOut = () => {
    localStorage.removeItem('token');
    navigate("/");
    window.location.reload();
  }

  const redirect = (path) => {
    navigate(path)
  }

  useEffect(() => {
    let ls_token = JSON.parse(localStorage.getItem('token'))
    setToken(ls_token)
  }, []);

  return (
    <>
      <Toolbar sx={{ backgroundColor: 'black' }}>
        <Link href='/'>
          <Img src={require("../../images/logo_pedregal.png")} />
        </Link>

        {token ?
          <>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={3}
            >

              <Grid item >
                <Button
                  variant='outlined'
                  size="small"
                  color='error'
                  onClick={() => redirect('/report')}
                >
                  Reportes
                </Button>
              </Grid>

              <Grid item >
                <Button
                  variant="outlined"
                  size="small"
                  color='error'
                  onClick={logOut}
                >
                  Log out
                </Button>
              </Grid>
            </Grid>
          </>
          :
          <></>
        }
      </Toolbar>
    </>
  );
}

// Header.propTypes = {
//   sections: PropTypes.arrayOf(
//     PropTypes.shape({
//       title: PropTypes.string.isRequired,
//       url: PropTypes.string.isRequired,
//     }),
//   ).isRequired,
//   title: PropTypes.string.isRequired,
// };

export default Header;
