import * as React from 'react';
import { Grid } from '@mui/material';
import Header from '../blog/Header'
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import { getData } from '../../utils/Fetch'
import moment from 'moment';

export default function ReportDetail() {
    const { id } = useParams();

    const [tipoReporte, setTipoReporte] = React.useState('');
    const [fechaDesde, setFechaDesde] = React.useState('');
    const [fechaHasta, setFechaHasta] = React.useState('');
    const [descripcion, setDescripcion] = React.useState('');
    const [conocimientoSucesos, setConocimientoSuceso] = React.useState('');
    const [personasExternas, setPersonasExternas] = React.useState('');
    const [ocultanHechos, setOcultanHechos] = React.useState('');
    const [personalConoce, setPersonalConoce] = React.useState('');
    const [personalInvolucrado, setPersonalInvolucrado] = React.useState('');
    const [beneficiaPersonas, setBeneficiaPersonas] = React.useState('');
    const [testigos, setTestigos] = React.useState('');
    const [relacionEmpresa, setRelacionEmpresa] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [celular, setCelular] = React.useState('');
    const [personComponent, setPersonComponent] = React.useState([]);
    const [placesComponent, setPlacesComponent] = React.useState([]);
    const [evidences, setEvidences] = React.useState(false);

    const PersonComponent = (data) => {
        let person_component = []
        for (let p of data) {
            let dividir = person_component.length > 0 ? <Divider variant="fullWidth" /> : <></>
            const component =
                <>
                    {dividir}
                    <Typography variant="body2" gutterBottom >
                        <b>Nombre: </b> {p.name}
                    </Typography>
                    <Typography variant="body2" gutterBottom >
                        <b>Apellido: </b> {p.lastName}
                    </Typography>
                    <Typography variant="body2" gutterBottom >
                        <b>Cargo: </b> {p.cargo}
                    </Typography>
                    <Typography variant="body2" gutterBottom >
                        <b>Empresa: </b> {p.company}
                    </Typography>

                </>
            person_component.push(component)
        }
        return person_component
    }

    const PlacesComponent = (data) => {
        let places_component = []
        for (let p of data) {
            let dividir = places_component.length > 0 ? <Divider variant="fullWidth" /> : <></>
            const component =
                <>
                    {dividir}
                    <Typography variant="body2" gutterBottom >
                        <b>Lugar: </b> {p.name}
                    </Typography>
                </>
            places_component.push(component)
        }
        return places_component
    }

    function forceDownload(blob, filename) {
        var a = document.createElement('a');
        a.download = filename;
        a.href = blob;
        // For Firefox https://stackoverflow.com/a/32226068
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    const download = () => {
        fetch('https://lineaeticapedregal.com/api/v1/evidence/download/' + id)
            .then((response) => {
                console.log(response)
                return response.blob()
            })
            .then((blob) => {
                const date = moment().format("DD-MM-yyyy")
                var file = window.URL.createObjectURL(blob);
                const filename = 'Reporte_' + id + '-' + date
                forceDownload(file, filename);
                // console.log('file link', file)
                // window.location.assign(file);
            })
    }

    useEffect(() => {
        getData('https://lineaeticapedregal.com/api/v1/reports/' + id).then((response) => {

            console.log(response)
            // console.log(response.evidences[0].evidence.data)
            // const img64 = new Buffer(response.evidences[0].evidence.data)
            // const bufferData = Buffer.from(response.evidences[0].evidence.data)
            // const base64String = Buffer.from(bufferData).toString('base64');
            // const blob = new Blob([bufferData], { type: 'image/png' });
            // const imageUrl = URL.createObjectURL(blob);

            if (!response.error) {
                setTipoReporte(response.tipos.name)
                setFechaDesde(moment(response.fechaInicio).format('DD-MM-yyyy'))
                setFechaHasta(moment(response.fechaFin).format('DD-MM-yyyy'))
                setDescripcion(response.description)
                setConocimientoSuceso(response.res1)
                setPersonasExternas(response.res2)
                setOcultanHechos(response.res3)
                setPersonalConoce(response.res4)
                setPersonalInvolucrado(response.res5)
                setBeneficiaPersonas(response.res6)
                setTestigos(response.res7)
                setOcultanHechos(response.res8)
                setRelacionEmpresa(response.contact.relation)
                setEmail(response.contact.email)
                setCelular(response.contact.phone)

                setPersonComponent(PersonComponent(response.persons))
                setPlacesComponent(PlacesComponent(response.places))
                if (response.evidences.length) setEvidences(true)
            }
            else { window.alert("Error al cargar")}

        })


    }, []);


    return (
        <>
            <Header />
            <Container fixed>
                <Grid container
                    style={{ 'backgroundColor': '#f1f1f1', 'padding': '1%' }}
                    justifyContent="center"
                    direction="column"
                    alignItems="center"
                >

                    <Grid item >
                        <Card >
                            <Box sx={{ display: 'block', backgroundColor: 'red', color: 'white', alignItems: 'center' }} >
                                <Typography variant="h5" gutterBottom align='center' style={{ 'padding': '3%' }}>
                                    <b> N° Reporte: {id} </b>
                                </Typography>
                            </Box>

                            {/* INFORMACION */}
                            <Grid container
                                direction="row"
                                style={{ padding: '2%' }}
                            >
                                <Grid item>
                                    <Typography variant="h5" gutterBottom >
                                        <b>Información</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider variant="fullWidth" />

                            {/* TIPODE DE REPORTE */}
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={5} xs={12}>
                                    <Typography variant="subtitle1" gutterBottom >
                                        <b>Tipo de reporte</b>
                                    </Typography>
                                </Grid>

                                <Grid item sm={5} xs={12}>
                                    <Typography variant="body2" gutterBottom >
                                        {tipoReporte}
                                    </Typography>
                                </Grid>

                            </Grid>


                            {/* PERSONAS INVOLUCRADAS */}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={5} xs={12} >
                                    <Typography variant="subtitle1" gutterBottom >
                                        <b>Identificación de personas involucradas</b>
                                    </Typography>
                                </Grid>
                                <Grid item sm={5} xs={12}>
                                    {personComponent}
                                </Grid>

                            </Grid>

                            {/* LUGARES */}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={5} xs={12}>
                                    <Typography variant="subtitle1" gutterBottom >
                                        <b>Identificación del lugar del suceso</b>
                                    </Typography>
                                </Grid>

                                <Grid item sm={5} xs={12}>
                                    {placesComponent}
                                </Grid>
                            </Grid>

                            {/* FECHA DE SUCESO */}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={5} xs={12}>
                                    <Typography variant="subtitle1" gutterBottom >
                                        <b>Fechas del suceso</b>
                                    </Typography>
                                </Grid>

                                <Grid item sm={5} xs={12}>
                                    <Typography variant="body2" gutterBottom >
                                        {fechaDesde}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom >
                                        {fechaHasta}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {/* DESCRIPCION */}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={5} xs={12}>
                                    <Typography variant="subtitle1" gutterBottom >
                                        <b>Descripción del suceso</b>
                                    </Typography>
                                </Grid>

                                <Grid item sm={5} xs={12}>
                                    <Typography style={{ width: '100%' }}>
                                        {descripcion}
                                    </Typography>
                                </Grid>

                            </Grid>

                            {/* INFORMACION ADICIONAL */}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={12}>
                                    <Typography variant="h5" gutterBottom >
                                        <b>Información adicional</b>
                                    </Typography>
                                </Grid>
                            </Grid>

                            {/* COMO?*/}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={5} xs={12} >
                                    <Typography variant="subtitle1" gutterBottom >
                                        <b>¿Cómo tuvo conocimiento de los sucesos reportados?</b>
                                    </Typography>
                                </Grid>

                                <Grid item sm={5} xs={12} >
                                    <Typography style={{ width: '100%' }}>
                                        {conocimientoSucesos}
                                    </Typography>
                                </Grid>

                            </Grid>

                            {/* PERSONAS EXTERNAS?*/}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={5} xs={12} >
                                    <Typography variant="subtitle1" gutterBottom >
                                        <b>¿Este suceso está relacionado a personas externas a la compañía?</b>
                                    </Typography>
                                </Grid>

                                <Grid item sm={5} xs={12} >
                                    <Typography style={{ width: '100%' }}>
                                        {personasExternas}
                                    </Typography>
                                </Grid>

                            </Grid>

                            {/* SE OCULTAN HECHOS?*/}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={5} xs={12} >
                                    <Typography variant="subtitle1" gutterBottom >
                                        <b>A su parecer ¿Cree que se ocultan los hechos de alguna forma?</b>
                                    </Typography>
                                </Grid>

                                <Grid item sm={5} xs={12} >
                                    <Typography style={{ width: '100%' }}>
                                        {ocultanHechos}
                                    </Typography>
                                </Grid>

                            </Grid>

                            {/* EL PERSONAL CONOCE EL SUCESO?*/}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={5} xs={12} >
                                    <Typography variant="subtitle1" gutterBottom >
                                        <b>Aparte de Ud. ¿Algún personal de la empresa conoce el suceso reportado?</b>
                                    </Typography>
                                </Grid>

                                <Grid item sm={5} xs={12} >
                                    <Typography style={{ width: '100%' }}>
                                        {personalConoce}
                                    </Typography>
                                </Grid>

                            </Grid>

                            {/* QUE RELACION EXISTE?*/}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={5} xs={12} >
                                    <Typography variant="subtitle1" gutterBottom >
                                        <b>¿Qué relación existe entre el personal involucrado en el suceso reportado?</b>
                                    </Typography>
                                </Grid>

                                <Grid item sm={5} xs={12} >
                                    <Typography style={{ width: '100%' }}>
                                        {personalInvolucrado}
                                    </Typography>
                                </Grid>

                            </Grid>


                            {/* COMO SE BENEFICIAN ?*/}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={5} xs={12} >
                                    <Typography variant="subtitle1" gutterBottom >
                                        <b>¿Cómo se benefician las personas en el hecho reportado?</b>
                                    </Typography>
                                </Grid>

                                <Grid item sm={5} xs={12} >
                                    <Typography style={{ width: '100%' }}>
                                        {beneficiaPersonas}
                                    </Typography>
                                </Grid>

                            </Grid>


                            {/* OTROS TESTIGOS ?*/}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={5} xs={12} >
                                    <Typography variant="subtitle1" gutterBottom >
                                        <b>¿Conoce a otros testigos que pueden apoyar su versión y contribuir con evidencia del caso?</b>
                                    </Typography>
                                </Grid>

                                <Grid item sm={5} xs={12} >
                                    <Typography style={{ width: '100%' }}>
                                        {testigos}
                                    </Typography>
                                </Grid>

                            </Grid>


                            {/* OPCIONES DE CONTACTO */}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={12}>
                                    <Typography variant="h5" gutterBottom >
                                        <b>Opciones de Contacto</b>
                                    </Typography>
                                </Grid>
                            </Grid>

                            {/* RELACION CON LA EMPRESA ?*/}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={5} xs={12} >
                                    <Typography variant="subtitle1" gutterBottom >
                                        <b>¿Cuál es su relación con la empresa?</b>
                                    </Typography>
                                </Grid>

                                <Grid item sm={5} xs={12} >
                                    <Typography style={{ width: '100%' }}>
                                        {relacionEmpresa}
                                    </Typography>
                                </Grid>

                            </Grid>


                            {/* Email */}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={5} xs={12} >
                                    <Typography variant="subtitle1" gutterBottom >
                                        <b>Email</b>
                                    </Typography>
                                </Grid>

                                <Grid item sm={5} xs={12} >
                                    <Typography style={{ width: '100%' }}>
                                        {email}
                                    </Typography>
                                </Grid>

                            </Grid>

                            {/* Telefono */}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={5} xs={12} >
                                    <Typography variant="subtitle1" gutterBottom >
                                        <b>N° de Contacto</b>
                                    </Typography>
                                </Grid>

                                <Grid item sm={5} xs={12} >
                                    <Typography style={{ width: '100%' }}>
                                        {celular}
                                    </Typography>
                                </Grid>

                            </Grid>


                            {/* OPCIONES DE CONTACTO */}
                            {/* <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                style={{ padding: '2%' }}
                            >
                                <Grid item sm={12}>
                                    <Typography variant="h5" gutterBottom >
                                        <b>Imagenes Subidas</b>
                                    </Typography>
                                </Grid>
                            </Grid> */}

                            {/* IMG */}
                            <Divider variant="fullWidth" />
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ padding: '2%' }}
                            >
                                <Grid item xs={12} sm={6} md={4}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color='error'
                                        fullWidth
                                        disabled={evidences ? false : true}
                                        onClick={download}
                                    >
                                        {evidences ? 'Descargar Imágenes' : 'Sin Imágenes'}
                                    </Button>
                                </Grid>

                            </Grid>


                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
