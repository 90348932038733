import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import Header from '../blog/Header'
import IconButton from '@mui/material/IconButton';
import { useEffect } from 'react';
import moment from 'moment';

import { getData } from '../../utils/Fetch'

import StickyHeadTable from '../table/EasyTable'
import { useNavigate } from "react-router-dom";
import ArticleIcon from '@mui/icons-material/Article';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Tooltip from '@mui/material/Tooltip';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Container from '@mui/material/Container';


function createData(id, fecha_creacion, tipo, involucrados, sede, lugar, fecha, descripcion, estado, action) {
    return { id, fecha_creacion, tipo, involucrados, sede, lugar, fecha, descripcion, estado, action };
}

const columns = [
    { id: 'id', label: 'ID' },
    { id: 'fecha_creacion', label: 'Fecha de Creación', minWidth: 70 },
    { id: 'tipo', label: 'Tipo de reporte' },
    { id: 'involucrados', label: 'Persona Involucradas' },
    { id: 'sede', label: 'Sede', minWidth: 120 },
    { id: 'lugar', label: 'Lugar del Suceso' },
    { id: 'fecha', label: 'Fecha del Suceso', minWidth: 70 },
    { id: 'descripcion', label: 'Descripcion' },
    { id: 'estado', label: 'Estado' },
    { id: 'action', label: 'Acciones', align: 'center', minWidth: 80 },
]


export default function CreateReport() {
    let navigate = useNavigate();
    const [age, setAge] = React.useState('');
    const [rows, setRows] = React.useState([]);
    const [estatus, setEstatus] = React.useState();
    const [id, setId] = React.useState();
    const [listEstatus, setListEstatus] = React.useState();
    const [token, setToken] = React.useState();
    const [observacion, setObservacion] = React.useState();
    const [dataReport, SetDataReport] = React.useState([]);


    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = (data) => {

        setEstatus(data.conditionId)
        setObservacion(data.observation)
        setId(data.id)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEstatus('')
        setObservacion('')
        setId('')
    };

    async function handleOK() {

        var requestOptions = {
            method: 'PATCH',
            body: JSON.stringify({ 
                conditionId: estatus,
                observation: observacion
            }),
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow'
        };

        const response = await fetch("https://lineaeticapedregal.com/api/v1/reports/" + id, requestOptions)
        const rp = await response.json()
        console.log("PATCH", rp)

        if (!rp.id) window.alert("Error AL ACTUALIZAR")

        else obtenerReportes(token); setOpen(false);
    }

    const obtenerReportes = (ls_token) => {
        let list = []
        getData('https://lineaeticapedregal.com/api/v1/reports', { 'Authorization': ls_token.token }).then((response) => {
            console.log(response)
            if (response.error) {
                localStorage.removeItem('token');
                navigate("/login");
            }
            else {
                for (let data of response) {
                    let person_component = []
                    let place_component = []
                    let sede_component = []
                    for (let p of data.persons) {
                        person_component.push(
                            <Grid>
                                <Typography variant='p'>- {p.name} {p.lastName}</Typography>
                            </Grid>
                        )
                    }
                    if (data.sede){                        
                        sede_component.push(
                            <Grid>
                                <Typography variant='p'>{data.sede.sede}</Typography>
                            </Grid>
                        )
                    }
                    for (let pl of data.places) {
                        place_component.push(
                            <Grid>
                                <Typography variant='p'>- {pl.name}</Typography>
                            </Grid>
                        )
                    }

                    const action_component =
                        <Grid container spacing={1}>
                            <Grid item>
                                <Tooltip title="Detalle">
                                    <IconButton
                                        variant="contained"
                                        size='large'
                                        edge='end'
                                        href={"/report/" + data.id}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                <Tooltip title="Editar Estado">
                                    <IconButton
                                        variant="contained"
                                        size='large'
                                        edge='end'
                                        onClick={(() => handleClickOpen(data))}
                                    >
                                        <EditNoteIcon />
                                    </IconButton>
                                </Tooltip>

                            </Grid>
                        </Grid>

                    list.push(
                        createData(
                            data.id,
                            moment(data.createdAt).format('DD-MM-yyyy'),
                            data.tipos.name,
                            person_component,
                            sede_component,
                            place_component,
                            moment(data.fechaInicio).format('DD-MM-yyyy'),
                            data.description,
                            data.condition.name,
                            action_component
                        )
                    )
                }
                setRows(list)
            }
        })
    };

    useEffect(() => {

        let ls_token = JSON.parse(localStorage.getItem('token'))
        if (ls_token) {
            console.log(ls_token.token)
            obtenerReportes(ls_token)
            setToken(ls_token)

            getData('https://lineaeticapedregal.com/api/v1/conditions').then((response) => {
                console.log(response)
                let component = []
                for (let x of response) {
                    component.push(<MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)
                }
                setListEstatus(component)
            })
        }

    }, []);

    return (
        <>

            <Header />

            <Grid container style={{ 'backgroundColor': '#f1f1f1', 'paddingBottom': '1%' }} justifyContent="center" direction="column" >
                <Grid item xs={12} style={{ 'margin': '2%' }}>
                    <Typography variant='h4' style={{ 'width': '100%' }}>
                        <b>Lista de Reportes</b>
                    </Typography>
                </Grid>

                <Container>
                    <StickyHeadTable headers={columns} rows={rows} ></StickyHeadTable>
                </Container>

                <React.Fragment>

                    <Dialog
                        // fullScreen={fullScreen}
                        // style={{width:'50vh'}}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title" style={{ width: '450px', maxWidth: '100%'}}>
                            <b>Reporte N° {id}</b>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Actualizar Estatus
                            </DialogContentText>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <Select
                                        id="demo-simple-select"
                                        placeholder='Seleccionar'
                                        value={estatus}
                                        fullWidth
                                        size='small'
                                        onChange={(event) => { setEstatus(event.target.value) }}
                                    >
                                        {listEstatus}
                                    </Select>
                                </Grid>
                            </Grid>
                            <DialogContentText>
                                Observaciones
                            </DialogContentText>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-multiline-controlled"
                                        multiline
                                        fullWidth
                                        rows={4}
                                        placeholder='Escriba su texto aqui'
                                        value={observacion}
                                        onChange={(event) => { setObservacion(event.target.value) }}
                                        // style={{width: '100vh'}}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color='inherit' variant='outlined'>
                                Cancelar
                            </Button>
                            <Button onClick={handleOK} variant='outlined' color='success'>
                                Actualizar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>

            </Grid>

        </>
    );
}
